.client-link {
    cursor: pointer;

    @include link-icon(true);
}

.highlight-link {
    span {
        color: blue;
    }
}