.notifications {
    position: fixed;
    bottom: 1.2rem;
    right: var(--body-p);
    z-index: map-get($z-index, notification);
    width: min(18rem, 90vw);

    li {
        &:before {
            display: none; // overwrite default style
        }
    }

    @include respond-to(landscape) {
        bottom: 2rem;
    }
}

$_thumb-w: 2.6rem;
$_close-w: 1rem;

.notifications__bubble {
    display: grid;
    grid-template-columns: $_thumb-w auto $_close-w;
    grid-gap: var(--notification-p);
    padding: var(--notification-p);
    align-items: center;
    background: linear-gradient(to left, rgba(196, 196, 196, 0.2), rgba(196, 196, 196, 0.5));
    backdrop-filter: blur(1rem);
    -webkit-backdrop-filter: blur(1rem);
    position: relative;
    border-radius: 0.6rem;
    @include text-sm;

    animation: notification-in $trans-time--xs $trans-func--default 2s backwards,
               notification-out $trans-time--xs $trans-func--default 18s forwards;

    h2 {
        @include text-sm;
    }
}

.notifications__image {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: $_thumb-w;
        height: $_thumb-w;
        border-radius: 50%;
        overflow: hidden;
    }
}

.notifications__text {
    opacity: 0.6;
}

.notifications__close {
    position: absolute;
    top: var(--notification-p);
    right: var(--notification-p);
    margin: -0.1rem;

    @media (hover: hover) {
        opacity: 0;
        transition: opacity $trans-time--xs $trans-func--default;

        .notifications__bubble:hover & {
            opacity: 1;
        }
    }
}

.notifications__close-icon {
    all: unset; // unset button styles
    cursor: pointer;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        inset: -0.6rem;
    }
}