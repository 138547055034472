/*------------------------------------*\
  #ROOT
\*------------------------------------*/

/**
 * Default definition of custom properties
 */

:root {
    // Add token vars
    @include create-custom-properties($text-colors, false, 'text-');
    @include create-custom-properties($bg-colors, false, 'bg-');
    @include create-custom-properties($border-colors, false, 'border-');

    // Add custom properties for ui definitions
    @include create-custom-properties($ui-props, true);

    // @media (prefers-color-scheme: dark) {
    //     // invert colors for darkmode
    //     @include create-custom-properties($text-colors-dark, false, 'text-');
    //     @include create-custom-properties($bg-colors-dark, false, 'bg-');
    // }

    // Target iOS to handle rounded corner viewports
    @supports (-webkit-touch-callout: none) {
        --ios-inset: 1.6rem;

        @include respond-to(md) {
            // the corners on bigger devices do not cut so deep
            --ios-inset: 0.3rem;
        }
    }
}
