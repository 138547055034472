input[type="file" i]::-webkit-file-upload-button,
.btn {
    all: unset;
    @include text-sm;
    cursor: pointer;
    border: 1px solid currentColor;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1.4rem !important;
    border-radius: 0.4rem;
    text-transform: uppercase;
    white-space: nowrap;

    @include hover() {
        background: var(--bg-inverted);
        color: var(--text-inverted);
    }
}

.btn {
    &:focus-within {
        @include focus-style();
    }
}