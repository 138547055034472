/*------------------------------------*\
  #PROSE
\*------------------------------------*/

.prose {
    font-size: 1.4rem;
    line-height: 1;
    letter-spacing: -0.02em;
    --prose-flow: 1rem;
    word-break: break-word;

    a:hover,
    s {
        text-decoration-thickness: 7%;
    }

    h1,
    h2,
    h3,
    h4 {
        font-size: inherit;

        & + p,
        & + h2,
        & + h3,
        & + h4,
        & + ul,
        & + span,
        & + ol {
            --flow-space: 0px;
        }
    }

    ul {
        list-style: disc outside;

        li {
            margin-left: 2ex;
        }
    }

    ol {
        li {
            counter-increment: step-counter;
            display: flex;

            &:before {
                content: counter(step-counter);
                flex-shrink: 0;
                width: 3ex;
                display: inline-block;
                font-variant-numeric: tabular-nums;
            }
        }
    }

    a {
        @include link-icon();

        &:hover {
            text-decoration: underline;
            text-decoration-thickness: 7%;
        }
    }

    @include respond-to(landscape) {
        font-size: 3rem;
        letter-spacing: -0.024em;
        --prose-flow: 0.75ex;
    }
}

.prose__header {

    & > * + * {
        margin-top: var(--prose-flow);
    }

    @include respond-to(landscape) {
        display: flex;
        justify-content: space-between;

        > * {
            width: 49.5%;
        }

        & > * + * {
            margin-top: 0;
        }
    }
}

.prose__content {
    margin-top: var(--prose-flow);
    padding-bottom: 2rem;

    &:first-child {
        margin-top: 0;
    }
}
