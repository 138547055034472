.main {
    position: relative;
}

.main__footer {
    padding: var(--body-p) calc(var(--body-p) + 0.3rem + var(--ios-inset));
    position: absolute;
    bottom: 0;
    right: 0;

    .has-clock & {
        visibility: hidden;
    }

    // Show fixed footer on imprint
    .prose ~ & {
        position: fixed;
        left: 0;
        background: var(--bg-default);
        display: flex;
        flex-direction: row-reverse;
    }

    a {
        display: flex; // fix height
    }

    @include respond-to(landscape) {
        position: fixed;
    }
}