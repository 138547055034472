.bare-list {
    @include responsive-selector(true, landscape) {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        li {
            &:not(:last-child) {
                button,
                a {
                    display: inline-block;

                    &:after {
                        content: '\00a0•\00a0';
                        // content: ", ";
                    }
                }
            }
        }
    }
}