///*----------------------------------*\
//  #ANIMATIONS
//\*----------------------------------*/

@keyframes notification-in {
    0% {
        transform: scale(0.6);
        // transform: translate3d(110%, 0, 0);
        opacity: 0;
        backdrop-filter: blur(1rem) opacity(0);
    }
    100% {
        backdrop-filter: blur(1rem) opacity(1);
    }
}

@keyframes notification-out {
    0% {
        backdrop-filter: blur(1rem) opacity(1);
    }
    100% {
        transform: scale(0.6);
        // transform: translate3d(110%, 0, 0);
        opacity: 0;
        backdrop-filter: blur(1rem) opacity(0);
    }
}