.cursor {
    position: fixed;
    inset: 0;
    overflow: hidden;
    pointer-events: none;

    @media (hover: none) {
        display: none;
    }
}

.cursor__cursor {
    transform: translate3d(0.8rem, -0.2rem, 0);
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    z-index: 10;
    transition: transform 0.2s $trans-func--ease-out;

    &[data-direction="prev"] {
        transform: translate3d(calc(-100% - 0.6rem), -0.2rem, 0);
    }

    .show-cursor & {
        visibility: visible;
    }
}
