.clock-trigger {
    cursor: pointer;
    visibility: visible;

    @include link-icon();

    .has-clock & {
        opacity: 0;
    }
}

.clock {
    background-color: white;
    height: 100vh;
    pointer-events: none;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 2%;
    right: 0;
    z-index: 10;
    justify-content: center;
    align-items: center;

    &.clock--static {
        display: flex;
        pointer-events: all;
    }

    .has-clock & {
        display: flex;
    }

    @media (hover: none) {
        // Let clock be clickable to be closable on touch devices
        .has-clock & {
            pointer-events: all;
        }
    }

    @include respond-to(portrait) {
        flex-direction: column;
    }
}

.clock__item {
    width: min(80vw, 33vh);
    height: min(80vw, 33vh);
    margin: min(3vw, 3vw);
    background: url('../clock/clock-face-round.svg');
    visibility: visible;
    position: relative;

    &:last-child {
        background: url('../clock/clock-face.svg');
    }

    @include respond-to(landscape) {
        width: min(33vw, 80vh);
        height: min(33vw, 80vh);
    }
}

.clock__title {
    position: absolute;
    top: 32%;
    left: 0;
    right: 0;
    transform: translate3d(0, -50%, 0);
    text-align: center;
    display: flex;
    flex-direction: column;
    font-variant-numeric: tabular-nums;
    font-size: 0.9rem;

    @include respond-to(landscape) {
        font-size: 1.2rem;
    }
}

.clock__second {
    transition: transform 0.2s $trans-func--ease-out;
    position: absolute;
    top: 58%;
    left: 34%;
    width: 32%;
    height: 32%;
}

.clock__second-hand {
    background: url('../clock/second-hand.svg');
}

.clock__second-face {
    background: url('../clock/second-face.svg');
}

.clock__hand {
    transition: transform 0.2s ease-out;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.clock__hand-hour {
    background: url('../clock/hour-hand.svg');
}

.clock__hand-min {
    background: url('../clock/minute-hand.svg');
}

// @media (prefers-color-scheme: dark) {
//     .clock__item,
//     .clock__title,
//     .clock__second-hand {
//         -webkit-filter: invert(100%);
//         filter: invert(100%);
//     }
// }