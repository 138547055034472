/*------------------------------------*\
  #Slider
\*------------------------------------*/

.slider {
    height: 140vw;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    pointer-events: none;

    @include respond-to(landscape) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: auto;
    }


    @include respond-to(portrait) {
        // Pull up on article pages
        article + & {
            margin-top: calc(7rem * var(--line-height) * -1); // pull up slider
        }
    }

    &[aria-hidden="true"] {
        display: none;
    }
}

.slider__slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: var(--body-p);
    display: none;

    &.is-active {
        display: flex;
    }

    .has-clock & {
        visibility: hidden;
    }
}

.slider__slide-side {
    width: 50%;
    position: relative;
    display: flex;

    figure {
        position: relative;
        pointer-events: all;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: left center; // fix img position for webkit browsers

        [data-nav="true"] & {
            cursor: pointer;
        }
    }

    &[data-index] {
        img {
            display: none;
        }

        @for $i from 0 through 20 {
            &[data-index="#{$i}"] {
                picture:nth-child(#{$i + 1}) img {
                    display: block;
                }
            }
        }
    }

    .slider--centered & {
        justify-content: center;
    }
}

.slider__slide-side--left {
    flex-direction: row-reverse;

    // fix img position for webkit browsers
    img {
        object-position: right center;
    }

    .slider--centered & {
        flex-direction: row;
    }
}

.slider__title {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: var(--ios-inset);
    padding-right: calc(var(--ios-inset) + 7ex);
    max-width: calc(100% - 7ex - var(--ios-inset));
    -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 1rem); // mask overflow text
    mask-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 1rem); // mask overflow text
    margin-bottom: -0.2ex; // do not crop descenders
    padding-bottom: 0.2ex; // do not crop descenders
    display: flex;
    pointer-events: all;
    overflow: hidden;

    small {
        margin-left: var(--body-p); // double up padding to fix for rounded ios devices as well
        flex: 1 0 auto;

        &:not(:first-child) {
            margin-left: 1rem;
        }

        span {
            display: inline-block;
            min-width: 1rem;
        }

        a {
            @include link-icon();
            display: block;
        }
    }
}

.slider__hidden {
    display: none;

    .is-paused & {
        display: block;
    }
}
