/*------------------------------------*\
  #FLOW
\*------------------------------------*/

// Global spacing utility
// which reduced the pain of spacing individual elements
//
// https://every-layout.dev/layouts/stack/
// https://24ways.org/2018/managing-flow-and-rhythm-with-css-custom-properties/
//
// 1. Class for parents with spaced children
// 2. Flex declaration lets us group elements to the top and bottom
// of the vertical space with a margin-bottom: auto on a child.
// 3. Define specific margins via custom properties
// 4. Spaced children: All but the first get a top margin
.flow { // [1]
    display: flex; // [2]
    flex-direction: column; // [2]
    justify-content: flex-start;
}

.flow > * + * { // [4]
    margin-top: var(--flow-space);
}

.flow > * + *  {
    --flow-space: var(--prose-flow); // [3]
    margin-top: var(--flow-space);
}

