.article-header {
    min-height: calc(7 * var(--form-line)); // stay in background line grid
    overflow: hidden; // do not content box of h1 cover buttons in .header

    @include respond-to(landscape) {
        min-height: calc(8 * var(--form-line));
    }

    h1 {
        font-size: min(7rem, calc(0.5rem + 16vw));
        letter-spacing: -0.032em;
        margin-left: 2rem;

        @include respond-to(landscape) {
            margin-left: 3rem;
            margin-top: -1.05rem;
            font-size: min(7rem, calc(0.5rem + 12vw));
        }
    }

    sup {
        // lower real sup figures to not get cropped here
        font-size: 0.9em;
        position: static;
        vertical-align: middle;
    }

    .has-clock & {
        visibility: hidden;
    }
}